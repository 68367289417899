class BlogFilter extends HTMLElement {
  constructor() {
    super();
    // DOM elements
    this.filterButtons = null;
    this.filterToggle = null;
    this.filterDropdown = null;
    this.currentFilter = null;

    // State
    this.isMobile = window.innerWidth < 1024;
    this.sectionId = this.dataset.sectionId;
    this.blogHandle = this.dataset.blogHandle;
    this.resizeTimeout = null;
    this.cachedCurrentFilter = null;
  }

  // Lifecycle method called when the element is added to the DOM
  connectedCallback() {
    if (this.isConnected) {
      // Use requestAnimationFrame for smoother initial render
      requestAnimationFrame(() => this.setupComponent());
    } else {
      // Fallback for older browsers
      this.addEventListener('DOMContentLoaded', () => this.setupComponent());
    }
  }

  // Initialize the component
  setupComponent() {
    this.filterButtons = this.querySelectorAll('.filter-button');

    if (this.isMobile) {
      this.filterToggle = this.querySelector('#filter-toggle');
      this.filterDropdown = this.querySelector('#filter-dropdown');
      this.currentFilter = this.querySelector('#current-filter');
    }

    this.initializeFilter();
    this.addEventListeners();
  }

  // Set up initial filter state
  initializeFilter() {
    const currentFilterText = this.getCurrentFilterFromURL();
    this.updateFilterDisplay(currentFilterText);
  }

  // Parse the current filter from the URL
  getCurrentFilterFromURL() {
    // Return cached result if available
    if (this.cachedCurrentFilter) return this.cachedCurrentFilter;

    const path = window.location.pathname;
    const pathSegments = path.split('/').filter((segment) => segment !== '');

    let result;
    if (pathSegments.length === 0 || pathSegments[pathSegments.length - 1] === this.blogHandle) {
      result = 'All';
    } else {
      result = pathSegments[pathSegments.length - 1].replace(/-/g, ' ');
    }

    // Cache the result for future calls
    this.cachedCurrentFilter = result;
    return result;
  }

  // Update the UI to reflect the current filter
  updateFilterDisplay(filterText) {
    requestAnimationFrame(() => {
      if (this.isMobile && this.currentFilter) {
        this.currentFilter.textContent = filterText;
      }

      this.filterButtons.forEach((button) => {
        button.classList.toggle('active', button.textContent.trim() === filterText);
      });
    });
  }

  // Set up event listeners
  addEventListeners() {
    // Use event delegation for filter buttons
    this.addEventListener('click', (e) => this.handleFilterClick(e));

    if (this.isMobile && this.filterToggle) {
      this.filterToggle.addEventListener('click', () => this.toggleFilterDropdown());
    }

    // Add debounced resize listener
    window.addEventListener('resize', this.debouncedHandleResize.bind(this));
  }

  // Handle filter button clicks
  handleFilterClick(event) {
    const button = event.target.closest('.filter-button');
    if (!button) return;

    event.preventDefault();
    const filterText = button.textContent.trim();
    const filterUrl = button.getAttribute('href');

    this.updateFilterDisplay(filterText);

    if (this.isMobile && this.filterDropdown) {
      this.filterDropdown.classList.add('hidden');
    }

    this.updateFilteredResults(filterText, filterUrl);
  }

  // Toggle mobile filter dropdown visibility
  toggleFilterDropdown() {
    if (this.filterDropdown) {
      requestAnimationFrame(() => {
        this.filterDropdown.classList.toggle('hidden');
      });
    }
  }

  // Fetch and update filtered results using Shopify Section Rendering API
  updateFilteredResults(filter, filterUrl) {
    const url = new URL(filterUrl, window.location.origin).href + `?section_id=${this.sectionId}`;

    fetch(url, {
      headers: {
        Accept: 'text/html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const newContent = doc.querySelector(`#shopify-section-${this.sectionId}`);

        if (newContent) {
          const currentSection = document.querySelector(`#shopify-section-${this.sectionId}`);
          if (currentSection) {
            // Use DocumentFragment for better performance
            const fragment = document.createDocumentFragment();
            fragment.appendChild(newContent);

            requestAnimationFrame(() => {
              currentSection.innerHTML = '';
              currentSection.appendChild(fragment);

              // Update URL and reset component state
              const newUrl = new URL(filterUrl, window.location.origin).href;
              history.pushState({}, '', newUrl);
              this.cachedCurrentFilter = null; // Invalidate cache
              this.updateFilterDisplay(this.getCurrentFilterFromURL());
              this.setupComponent();

              // Dispatch custom event for external listeners
              this.dispatchEvent(new CustomEvent('filter-changed', { detail: { filter }, bubbles: true }));
            });
          } else {
            console.error(`Could not find section with id shopify-section-${this.sectionId}`);
          }
        } else {
          console.error('Could not find new content in the fetched HTML');
        }
      })
      .catch((error) => {
        console.error('Error fetching or processing data:', error);
        const filteredResults = document.getElementById('filtered-results');
        if (filteredResults) {
          filteredResults.innerHTML = '<p>An error occurred while loading articles. Please try again later.</p>';
        }
      });
  }

  // Debounce the resize handler to improve performance
  debouncedHandleResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => this.handleResize(), 250);
  }

  // Handle window resize events
  handleResize() {
    const wasModalDevice = this.isMobile;
    this.isMobile = window.innerWidth < 1024;

    // Reinitialize component if device type changes
    if (wasModalDevice !== this.isMobile) {
      this.setupComponent();
    }
  }
}

// Register the custom element
customElements.define('blog-filter', BlogFilter);
